import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const securityList: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "24px",
  position: "relative",
  padding: "0",
}

export const securityListItem: SxProps<Theme> = {
  maxWidth: "100%",
  minHeight: "40px",
  borderRadius: "8px",
}

export const securityListButton: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "48px 1fr",
  width: "100%",
  height: "100%",
  gap: "8px",
  padding: "8px 16px",
  alignItems: "center",
  borderRadius: "8px",
  color: "var(--secondary-text)",
  textAlign: "left",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  transition: "all 0.3s ease",
  cursor: "pointer",

  "&:hover,  &.Mui-selected, &.Mui-selected:hover": {
    color: "var(--filter-range-active)",
    backgroundColor: "var(--settings-tab-hover)",
    transition: "all 0.3s ease",
    span: {
      color: "var(--filter-range-active)",
    },
  },
  "&::after, &::before": {
    content: "''",
    position: "absolute",
    right: "16px",
    backgroundColor: "var(--secondary-text)",
    height: "2px",
    width: "8.5px",
    borderRadius: "16px",
    transition: "backgroundColor 0.3s ease",
  },
  "&::after": {
    transform: "translateY(-140%) rotate(45deg)",
  },
  "&::before": {
    transform: "translateY(140%) rotate(-45deg)",
  },
  "&:hover::after, &:hover::before, &.Mui-selected::after, &.Mui-selected::before":
    {
      backgroundColor: "var(--filter-range-active)",
      transition: "backgroundColor 0.3s ease",
    },

  span: {
    padding: "0",
  },
}

export const securityListIcon: SxProps<Theme> = {
  color: "var(--filter-range-active)",
}

export const securityListText: SxProps<Theme> = {
  margin: 0,
  span: {
    fontSize: "16px",
    fontWeight: 500,
    color: "var(--secondary-text)",
    lineHeight: "24px",
  },

  ".MuiListItemText-secondary": {
    fontSize: "12px",
    fontWeight: 400,
    color: "var(--secondary-text)",
    lineHeight: "16px",
    overflowWrap: "anywhere",
    paddingRight: "8px",
  },
}
