import { useState } from "react"
import { notificationsSettings } from "../../../consts"
import {
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material"

import GradientBtn from "../../../components/GradientBtn"
import {
  notificationsList,
  notificationsListItem,
  notificationsListButton,
} from "./notificationsSettingsStyle"
import s from "./styles.module.scss"

const NotificationsSettings = () => {
  const [checked, setChecked] = useState(
    notificationsSettings.map(({ text }) => text),
  )

  const handleToggle = (value: string) =>
    setChecked((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value],
    )

  return (
    <div className={s.notifications_container}>
      <List sx={notificationsList}>
        {notificationsSettings.map((item) => (
          <ListItem key={item.text} sx={notificationsListItem} disablePadding>
            <ListItemButton
              sx={notificationsListButton}
              role={undefined}
              onClick={() => handleToggle(item.text)}
              dense
              disableGutters
            >
              <item.icon />
              <ListItemText
                id={item.text}
                primary={item.text}
                sx={{ margin: 0, span: { fontWeight: 500 } }}
              />
              <ListItemIcon>
                <Checkbox
                  sx={{
                    margin: 0,
                    padding: 0,
                    right: 0,
                    color: "var(--secondary-text)",
                    "&.Mui-checked": {
                      color: "var(--filter-range-active)",
                    },
                  }}
                  edge="start"
                  checked={checked.includes(item.text)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": item.text }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <GradientBtn
        type="submit"
        title="Сохранить изменения"
        aria-label="save changes"
        textStyle={{ fontSize: "12px", textTransform: "uppercase" }}
        btnStyle={{
          width: "300px",
          maxWidth: "100%",
          height: "36px",
          padding: "10px 8px",
          fontSize: "12px",
        }}
        handleAction={() => {
          console.log(
            "Настройки уведомлений:",
            checked.join(", ").toLowerCase(),
          )
        }}
      />
    </div>
  )
}
export default NotificationsSettings
