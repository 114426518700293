import { useState } from "react"
import InterestsSettings from "../../../components/profile_settings/interests_settings"
import NotificationsSettings from "../../../components/profile_settings/notifications_settings"
import ProfileAndViewSettings from "../../../components/profile_settings/profile_and_view_settings"
import SecuritySettings from "../../../components/profile_settings/security"
import ProfileSettingsTabs from "../../../components/profile_settings/settings_tabs"
import s from "./styles.module.scss"

export interface ProfileSettingsProps {
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
}

const ProfileSettings = ({
  value: selectedTabValue,
  onChange,
}: ProfileSettingsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(selectedTabValue || "profile")
  const handleTabSelect = (
    event: React.SyntheticEvent,
    newValue: string,
  ): void => {
    setSelectedTab(newValue)
    onChange(event, newValue)
  }

  return (
    <div className={s.main}>
      <ProfileSettingsTabs value={selectedTab} onChange={handleTabSelect} />
      {selectedTab === "profile" && <ProfileAndViewSettings />}
      {selectedTab === "security" && <SecuritySettings />}
      {selectedTab === "interests" && <InterestsSettings />}
      {selectedTab === "notifications" && <NotificationsSettings />}
    </div>
  )
}

export default ProfileSettings
