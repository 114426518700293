import { useUploadAvatarToTempFolderMutation } from "../app/data/tribeSwaggerApi"
import { useState, useEffect } from "react"

export type UploadAvatarQuery = {
  upload: (image: ArrayBuffer) => void
  isLoading: boolean
  error?: string
  resultUrl: string
}

export const useUploadAvatarToServer = (userId: string): UploadAvatarQuery => {
  const [trigger, { data, error, isLoading }] =
    useUploadAvatarToTempFolderMutation()

  const [result, setResultUrl] = useState<string>("")

  useEffect(() => {
    if (data) {
      const value = Object.values(data)[0]
      setResultUrl(value)
    }
  }, [data])
  const uploadHandler = (image: ArrayBuffer) => {
    const uint8Array = new Uint8Array(image)
    trigger({
      userId,
      imageDto: {
        //@ts-ignore
        image: [...uint8Array],
        content_type: "image/jpeg",
      },
    })
  }

  return {
    upload: uploadHandler,
    isLoading: isLoading,
    error: error ? "Ошибка загрузки" : undefined,
    resultUrl: result,
  }
}
