import { FC, useEffect, useState } from "react"
import { Avatar, Button } from "@mui/material"

import { useUploadAvatarToServer } from "../../../hooks/useUploadAvatarToServer" /*added */
import { useDeleteUser } from "../hooks/useDeleteUser"
import { useProfileSettingsEditor } from "../hooks/useProfileSettings"

import { selectUserId } from "../../../app/data/appTokensStore"
import { useAppSelector } from "../../../app/hooks"

import GradientBtn from "../../../components/GradientBtn" /*added */
import AvatarFallback from "../../../assets/svg/menu-icons-pack/avatar.svg?react"
import ClearInputIcon from "../../../assets/svg/menu-icons-pack/close-round-icon-menu.svg?react"
import PhotoIcon from "../../../assets/svg/photo.svg?react"
import ChangeIcon from "../../../assets/svg/change.svg?react" /*added */
import LocationSpinner from "../../../common/ui-kit/spinner"

import { avatarUploadBtn, userAvatar } from "./profileAndViewStyle"
import s from "./styles.module.scss"

//TODO: выпилить из package.json вместе с tailwind import { useForm, SubmitHandler } from "react-hook-form"
//TODO: добавить валидацию
//TODO: добавить опцию убрать аватар
//TODO: добавить надпись, что изменения сохранены
//TODO: проверить, дождаться ответа Никиты про дату и поменять
const ProfileAndViewSettings: FC = () => {
  const [profileInfo, setProfileInfo] = useState<{
    username: string
    firstName: string
    lastName: string
    birthday: string
    avatar: string
    avatarToDelete: string[]
  }>({
    username: "",
    firstName: "",
    lastName: "",
    birthday: "",
    avatar: "",
    avatarToDelete: [],
  })

  const [preview, setPreview] = useState<string>("")

  const userId = useAppSelector(selectUserId)

  const { updateProfile, isLoading, error, currentUser } =
    useProfileSettingsEditor()
  const { upload, resultUrl } = useUploadAvatarToServer(
    userId ? userId.toString() : "",
  )
  const { deleteUser } = useDeleteUser()

  useEffect(() => {
    if (currentUser) {
      setProfileInfo((prev) => ({
        ...prev,
        username: currentUser.username ?? "",
        firstName: currentUser.first_name ?? "",
        lastName: currentUser.last_name ?? "",
        birthday: currentUser.birthday ?? "",
        avatar: currentUser.avatar_url ?? "",
        avatarToDelete: currentUser.avatars_filenames_for_deleting ?? [],
      }))
    }
  }, [currentUser])

  const capitalize = (str: string): string =>
    str
      .trim()
      .toLowerCase()
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ")

  const today = new Date()
  const maxBirthday = new Date(today.setFullYear(today.getFullYear() - 12))
    .toISOString()
    .slice(0, 10) // min age 12 years
  //const minBirthday = new Date(today.setFullYear(today.getFullYear() - 120))
  //  .toISOString()
  //  .slice(0, 10);
  // max age 120 years
  //TODO: return, when backend fixes date format for return

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile && userId) {
      try {
        const fileBuffer = await selectedFile.arrayBuffer()
        upload(fileBuffer)
        const previewUrl = URL.createObjectURL(selectedFile)
        setPreview(previewUrl)
        setProfileInfo((prev) => ({ ...prev, avatar: resultUrl }))
      } catch (error) {
        console.error("Error uploading file:", error)
      }
    } else {
      console.warn("No file selected or user ID is missing")
    }
  }

  const handleAvatarRemove = (): void => {
    setPreview("")
    setProfileInfo((prev) => ({
      ...prev,
      avatar: "",
      avatarToDelete: [...prev.avatarToDelete, prev.avatar],
    }))
    upload?.(new ArrayBuffer(0)) //TODO: ждем нормальную ручку, которая позволяет удалять не только из временной папки, как получим -> вот это убрать
  }

  const handleSubmit = (): void => {
    console.log("submit")

    updateProfile({
      username: profileInfo.username,
      first_name: profileInfo.firstName,
      last_name: profileInfo.lastName,
      birthday: profileInfo.birthday,
      user_avatar: resultUrl,
      avatars_filenames_for_deleting: profileInfo.avatarToDelete, //ручка удаляет только из временной папки, ручки для удаления аватарки навсегда пока нет
    })
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <LocationSpinner />
      </div>
    )
  }
  if (error) {
    return <div>{error.error}</div>
  }

  return (
    <div className={s.user}>
      <div className={s.user__info}>
        <div className={s.user__avatar}>
          <Avatar src={preview || profileInfo.avatar} sx={userAvatar}>
            <AvatarFallback className={s.user__avatar_fallback} />
          </Avatar>
          <Button
            sx={avatarUploadBtn}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={
              preview || (currentUser && currentUser.avatar_url) ? (
                <ChangeIcon />
              ) : (
                <PhotoIcon />
              )
            }
          >
            <input
              type="file"
              accept="image/*, .png, .jpg"
              aria-label="add avatar"
              multiple={false}
              className={s.user__avatar_hidden_input}
              onChange={handleAvatarChange}
            />
          </Button>
          {(preview || (currentUser && currentUser.avatar_url)) && (
            <button
              className={s.user__avatar_delete}
              tabIndex={-1}
              aria-label="Удалить аватар"
              onClick={handleAvatarRemove}
            >
              <ClearInputIcon />
            </button>
          )}
        </div>
        <div className={s.user__username}>{`@${currentUser?.username}`}</div>
        {currentUser?.first_name || currentUser?.last_name ? (
          <div className={s.user__fullname}>
            {currentUser &&
              capitalize(
                `${currentUser.first_name || ""} ${
                  currentUser.last_name || ""
                }`,
              )}
          </div>
        ) : null}
      </div>
      <form
        name="profileSettingsForm"
        className={s.form__container}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      >
        <div className={s.form__items}>
          <div className={s.form__item}>
            <input
              type="search"
              placeholder="Логин *"
              className={s.form__input}
              spellCheck="false"
              autoComplete="off"
              autoCapitalize="off"
              maxLength={50}
              required
              value={profileInfo.username}
              onChange={(e) =>
                setProfileInfo((prev) => ({
                  ...prev,
                  username: e.target.value,
                }))
              }
            />
            {profileInfo.username && (
              <button
                className={s.form__input_clear}
                type="button"
                onClick={() =>
                  setProfileInfo((prev) => ({
                    ...prev,
                    username: "",
                  }))
                }
              >
                <ClearInputIcon />
              </button>
            )}
          </div>
          <div className={s.form__item}>
            <input
              type="search"
              placeholder="Имя"
              className={s.form__input}
              maxLength={50}
              spellCheck="false"
              autoComplete="off"
              autoCapitalize="words"
              style={{
                textTransform: "capitalize",
              }}
              value={profileInfo.firstName || ""}
              onChange={(e) =>
                setProfileInfo((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }))
              }
            />
            {profileInfo.firstName && (
              <button
                className={s.form__input_clear}
                type="button"
                onClick={() =>
                  setProfileInfo((prev) => ({
                    ...prev,
                    firstName: "",
                  }))
                }
              >
                <ClearInputIcon />
              </button>
            )}
          </div>
          <div className={s.form__item}>
            <input
              type="search"
              placeholder="Фамилия"
              className={s.form__input}
              maxLength={50}
              spellCheck="false"
              autoComplete="off"
              autoCapitalize="words"
              style={{
                textTransform: "capitalize",
              }}
              value={profileInfo.lastName || ""}
              onChange={(e) =>
                setProfileInfo((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }))
              }
            />
            {profileInfo.lastName && (
              <button
                className={s.form__input_clear}
                type="button"
                onClick={() =>
                  setProfileInfo((prev) => ({
                    ...prev,
                    lastName: "",
                  }))
                }
              >
                <ClearInputIcon />
              </button>
            )}
          </div>
          <div className={s.form__item}>
            <input
              type="date"
              max={maxBirthday}
              //min={minBirthday}
              className={s.form__input}
              maxLength={10}
              autoComplete="off"
              value={profileInfo.birthday || ""}
              onChange={(e) =>
                setProfileInfo((prev) => ({
                  ...prev,
                  birthday: e.target.value,
                }))
              }
            />
            {profileInfo.birthday && (
              <button
                className={s.form__input_clear}
                type="button"
                onClick={() =>
                  setProfileInfo((prev) => ({
                    ...prev,
                    birthday: "0001-01-01", // TODO: тут переделать, когда на бэке поправят формат даты для возврата (разрешат отдавать null)
                  }))
                }
              >
                <ClearInputIcon />
              </button>
            )}
          </div>
        </div>
        <GradientBtn
          type="submit"
          disabled={!profileInfo.username} //TODO: тут и ниже заменить на !isFormValid
          title="Сохранить изменения"
          aria-label="save changes"
          textStyle={{ fontSize: "12px", textTransform: "uppercase" }}
          btnStyle={{
            width: "100%",
            height: "36px",
            padding: "10px 8px",
            fontSize: "12px",
            ...(!profileInfo.username && {
              background: "var(--btns-disabled-bg)",
              color: "var(--btns-disabled-text)",
              pointerEvents: "none",
            }),
          }}
          handleAction={() => handleSubmit()}
        />
      </form>
      <button
        className={s.user__btn_delete}
        aria-label="Удалить аккаунт"
        onClick={() => deleteUser()}
      >
        удалить аккаунт
      </button>
    </div>
  )
}
export default ProfileAndViewSettings
