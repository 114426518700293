import { useState, MouseEvent } from "react"
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material"

import { securitySettings } from "../../../consts"
import {
  securityList,
  securityListItem,
  securityListButton,
  securityListIcon,
  securityListText,
} from "./securitySettingsStyle"

import { useGetUserQuery } from "../../../app/data/tribeSwaggerApi"
import { useAppSelector } from "../../../app/hooks"
import { selectUserId } from "../../../app/data/appTokensStore"

import s from "./styles.module.scss"

const SecuritySettings = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined,
  )

  const userId = useAppSelector(selectUserId)!.toString()
  const { data: { email, phone_number } = { email: "", phone_number: "" } } =
    useGetUserQuery({ userId })

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    setSelectedIndex(index)
  }

  const userAuthMethods: { [key: string]: string } = {
    phone:
      phone_number && phone_number.length >= 4
        ? maskPhone(phone_number)
        : "не указан",
    email: email ? maskEmail(email) : "не указана",
    password: maskPassword("password"), //TODO: я не получаю пароля, но его тут и не нужно
  }

  function maskPhone(phone: string) {
    if (phone.length < 8)
      return phone.slice(0, 1) + "*".repeat(phone.length - 2) + phone.slice(-1)
    else
      return phone.slice(0, 8) + "*".repeat(phone.length - 7) + phone.slice(-2)
    //TODO: проверить в каком формате приходит номер телефона -> в зависимости от этого оставить кол-во знаков в начале
  }

  function maskEmail(email: string) {
    const atIndex = email.indexOf("@")
    const maskedPart = email.slice(1, atIndex)
    return email.replace(maskedPart, "*".repeat(maskedPart.length))
  }

  function maskPassword(password: string) {
    return password.replace(/./g, "*")
  }

  const combinedSettings = securitySettings.map((setting) => ({
    ...setting,
    authValue: userAuthMethods[setting.alt],
  }))

  return (
    <div className={s.security_container}>
      <List sx={securityList}>
        {combinedSettings.map((item, index) => (
          <ListItem key={item.text} sx={securityListItem} disablePadding>
            <ListItemButton
              sx={securityListButton}
              role={undefined}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
              dense
              disableGutters
            >
              <ListItemIcon sx={securityListIcon}>
                <item.icon />
              </ListItemIcon>

              <ListItemText
                id={item.text}
                primary={item.text}
                secondary={item.authValue}
                sx={securityListText}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  )
}
export default SecuritySettings
