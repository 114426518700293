import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Tab,
  Tabs,
  Typography,
  Button,
} from "@mui/material"
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { sideMenuSettingsTabs } from "../../../consts"
import { useTheme } from "../../../hooks/useTheme"
import { useGetCityFromGeolocation } from "../hooks/useGetCityFromGeolocation"
import { useProfileSettingsEditor } from "../hooks/useProfileSettings"

import ExitIcon from "../../../assets/svg/menu-icons-pack/exit-icon-menu.svg?react"
import ColorthemeIcon from "../../../assets/svg/menu-icons-pack/fire-icon-menu.svg?react"
import GeolocationIcon from "../../../assets/svg/menu-icons-pack/geolocation-icon-menu.svg?react"
import { IOSSwitch } from "../iosswitch/IOSSwitch"
import {
  listButton,
  listItem,
  tabItem,
  tabList,
  tabsContainer,
  collapsedTabsContainer,
  tabsReturnBtn,
} from "./ProfileSettinsTabStyles"
import { useGetLocationQuery } from "../../../app/data/location/locationApi"

interface ProfileSettingsTabsProps {
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
}

const ProfileSettingsTabs: React.FC<ProfileSettingsTabsProps> = ({
  value,
  onChange,
}) => {
  const [geolocationEnabled, setGeolocationEnabled] = useState<boolean>(false)
  const [themeEnabled, setThemeEnabled] = useState<boolean>(false)
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const combinedStyle = {
    ...tabsContainer,
    ...(collapsed ? collapsedTabsContainer : ""),
  }

  const { isDarkTheme, onToggleTheme } = useTheme()
  const { updateProfile, currentUser } = useProfileSettingsEditor()
  const { getCity, city } = useGetCityFromGeolocation()
  const { data } = useGetLocationQuery()

  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser) {
      setGeolocationEnabled(currentUser.is_geolocation_available)
      currentUser.is_geolocation_available && getCity()
    }
  }, [currentUser])

  const handleGeolocationToggle = (): void => {
    updateProfile({
      user_avatar: "", //TODO: если убрать, прилетает 500
      is_geolocation_available: !geolocationEnabled,
    })
    console.log("Geolocation updated")
  }

  const handleThemeToggle = (): void => {
    setThemeEnabled(!themeEnabled)
    onToggleTheme()
  }

  return (
    <>
      {collapsed && (
        <Button
          sx={tabsReturnBtn}
          size="small"
          aria-label="return to settings"
          onClick={() => setCollapsed(false)}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Настройки
        </Button>
      )}

      <Box sx={combinedStyle}>
        <Tabs
          orientation="vertical"
          aria-label="settings tabs"
          sx={{
            minHeight: "256px",
            ".MuiTabPanel-hidden": {
              display: "none",
              transform: "translateX(-10%)",
            },
          }}
          TabIndicatorProps={{ sx: { display: "none" } }}
          value={value}
          onChange={onChange}
        >
          {sideMenuSettingsTabs.map((tab) => (
            <Tab
              sx={tabItem}
              icon={<tab.icon />}
              label={tab.label}
              iconPosition="start"
              key={tab.id}
              value={tab.id}
              onClick={() => setCollapsed(true)}
            />
          ))}
        </Tabs>
        <List sx={tabList} disablePadding>
          <ListItem sx={listItem} disablePadding>
            <ListItemButton
              sx={listButton}
              role={undefined}
              onClick={handleGeolocationToggle}
              dense
              disableGutters
            >
              <GeolocationIcon />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body2" fontWeight={500} width="100%">
                  Геолокация
                </Typography>
                {geolocationEnabled && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "var(--secondary-text)",
                      lineHeight: "16px",
                      minHeight: "16px",
                    }}
                  >
                    {city}
                  </Typography>
                )}
              </div>
              <IOSSwitch sx={{ m: 0 }} checked={geolocationEnabled} />
            </ListItemButton>
          </ListItem>

          <ListItem sx={listItem} disablePadding>
            <ListItemButton
              sx={listButton}
              role={undefined}
              onClick={handleThemeToggle}
              dense
              disableGutters
            >
              <ColorthemeIcon />
              <Typography variant="body2" fontWeight={500} width="100%">
                Тема
              </Typography>
              <span>{isDarkTheme ? "Темная" : "Светлая"}</span>
            </ListItemButton>
          </ListItem>

          <ListItem sx={listItem} disablePadding>
            <ListItemButton
              sx={listButton}
              role={undefined}
              onClick={() => navigate("/")}
              dense
              disableGutters
            >
              <ExitIcon />
              <Typography variant="body2" fontWeight={500} width="100%">
                Выход из настроек
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  )
}

export default ProfileSettingsTabs
