import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const userAvatar: SxProps<Theme> = {
  width: "100%",
  height: "100%",
}

export const avatarUploadBtn: SxProps<Theme> = {
  position: "absolute",
  padding: "0",
  right: 0,
  bottom: 0,
  zIndex: 50,
  width: "40px",
  minWidth: "40px",
  textAlign: "center",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: "var(--filter-range-active)",
  color: "var(--surface-bg)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "14px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "var(--filter-range-active)",
    transform: "scale(1.05)",
    transition: "all 0.3s ease",
  },
  "@media (max-width: 500px)": {
    width: "36px",
    height: "36px",
    minWidth: "36px",
  },
}
