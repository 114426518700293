import { Avatar } from "@mui/material"
import { useGetUserProfileQuery } from "../../../app/data/userDataSlice/userApi"
import { selectUserId } from "../../../app/data/appTokensStore"
import { avatarUrlDecorator } from "../../../app/data/avatarUrl"
import { useAppSelector } from "../../../app/hooks"
import { EventsCategoryActions } from "../../../hooks/profile_page_hooks/useProfileEventFieldData"
import ProfileCheckBox from "../../../components/profile_checkbox"
import AvatarFallback from "../../../assets/svg/menu-icons-pack/avatar.svg?react"
import { userAvatar } from "./profileDetailsStyle"
import s from "./styles.module.scss"

type ProfileDetailsProps = {
  setEventType: (evtType: EventsCategoryActions) => void
  currentEvtType: EventsCategoryActions
}

export const ProfileDetails = ({
  setEventType,
  currentEvtType,
}: ProfileDetailsProps) => {
  const id = useAppSelector(selectUserId)
  const { data } = useGetUserProfileQuery({ userId: id ?? 0 })

  if (!data) {
    return null
  }

  const { avatar_url, username, full_name, followers_count, followings_count } =
    data

  return (
    <div className={s.user}>
      <Avatar
        src={avatarUrlDecorator(avatar_url)}
        alt={avatar_url}
        sx={userAvatar}
      >
        <AvatarFallback className={s.user_avatar} />
      </Avatar>
      <div className={s.user_username}>
        {`@${username}`}
        <span></span>
      </div>
      {full_name && <div className={s.user_fullname}>{full_name}</div>}
      <div className={s.user_subscribe}>
        <div className={s.user_subscribe_item}>
          <div className={s.count}>{followers_count ?? 0}</div>
          <div className={s.title}>Подписчики</div>
        </div>
        <div className={s.user_subscribe_item}>
          <div className={s.count}>{followings_count ?? 0}</div>
          <div className={s.title}>Подписки</div>
        </div>
      </div>
      <ProfileCheckBox
        activeType={currentEvtType}
        setEventType={setEventType}
      />
    </div>
  )
}
